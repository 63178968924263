<template>
  <div class="indexBox">
    <div class="indexHeadBox">
      <div class="headTit">自助查图</div>
      <div class="headContent">
        <div class="textareaBox">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入口令、链接，图片保留3天请及时查看"
            v-model.trim="value"
          >
          </el-input>
        </div>

        <i @click="search" class="el-icon-search search"></i>
      </div>
      <div class="uploadBox">
        <el-upload
          :on-change="handleChange"
          :show-file-list="false"
          :auto-upload="false"
          class="upload"
          drag
        >
          <div
            style="color: cadetblue; font-size: 14px; text-align: center"
            class="el-upload__text"
          >
            🍎。二维码查图 请拖拽图或者点击这里。🍎
          </div>
        </el-upload>
      </div>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="address" label="任务操作" width="105">
          <template slot-scope="scope">
            <button
              @click="handleClick(scope.row)"
              type="text"
              class="el-button el-button--primary el-button--small"
            >
              <!----><!----><span>查看图片</span>
            </button>
          </template>
        </el-table-column>
        <el-table-column prop="zt" label="订单状态" width="105">
        </el-table-column>
        <el-table-column prop="wcsl" label="完成数量"> </el-table-column>
        <el-table-column prop="rwsl" label="任务数量"> </el-table-column>
        <el-table-column prop="ssnr" label="搜索内容" width="180">
        </el-table-column>
        <el-table-column prop="time" label="下单时间" width="160">
        </el-table-column>
        <el-table-column prop="type" label="任务类型" width="140">
        </el-table-column>
      </el-table>
    </div>
    <div class="tipBox">
      <span class="tipTit">查询不到数据一般有几种情况:</span>
      <el-divider></el-divider>
      <ul>
        <li>
          1. 查询口令：
          <span class="red">👏KB5P8l👱jcbKvO9📻，</span>
          去掉表情，保留部分字符即可，如：jcbKvO9
        </li>
        <li>2. 【https://dwz.cn/QKdQHd0y】这类链接使用完整信息查图</li>
        <li>
          3. 【<span class="red">7445635896738</span>
          事业有成】这类口令使用标红的部分查图
        </li>
        <li>
          4. 查询口令：
          <span class="red"
            >🗻🍹感激涕零🠵喜笑颜开👍
            十二年才能盼到一个吉祥的喜新年，高兴无比。新年有着吉祥好运发财安康的含义，人心所向。为达到新年废寝忘食努力奋斗打拼，追求美好。愿你新年，开羊荤喝洋酒享羊福!</span
          >
          ，去掉后面多余的文字，保留部表情符号中文字符即可，如：<span
            class="red"
            >🗻🍹感激涕零🠵喜笑颜开👍</span
          >
        </li>
        <li>5.查询不到的请联系客服查询！</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Message, Loading } from "element-ui";

export default {
  data() {
    return {
      value: "",
      tableData: [],
    };
  },
  mounted() {
    console.log("url = ", this.$route.query.url);
    let url = this.$route.query.url;
    if (url) {
      this.value = url;
      this.search();
    }
    let pddurl=this.getPddUrl()
    if(pddurl){
      this.value = pddurl;
      this.search();
    }
  },
  methods: {
       handleChange(eleFile) {
      let that = this;
      let file = eleFile.raw;
      new Promise(function (ok, no) {
        if (window.URL && window.URL.createObjectURL) {
          ok(window.URL.createObjectURL(file));
        } else if (typeof FileReader) {
          var reader = new FileReader();
          reader.onload = (evt) => {
            ok(evt.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          Message.error("浏览器不支持");
        }
      }).then((src) => {
        qrDecode.decodeByUrl(src, function (err, txt) {
          if (err) {
            console.log(err);
            Message.error(
              "抱歉，二维码不支持，请用链接或口令查询！"
            );
          } else {
		    Message({
            type: "success",
            showClose: true,
            message: "识别成功！",
          });
            that.value = txt;
          }
        });
      });
    },

    // 查看详情

    handleClick(item) {
      if (item.type == "拼多多红包") {
        this.$router.push({
          path: "/detail",
          query: {
            id: item.danhao,
          },
        });
      } 
	   
	  else {
         if (item.type == "拼多多低价单") {
        this.$router.push({
          path: "/detail",
          query: {
            id: item.danhao,
          },
        });
      }
	  else {
        this.$router.push({
          path: "/videoDetail",
          query: {
            id: item.danhao,
          },
        });
      }
      }
    },
    search() {
      if (!this.value) {
        Message({
          type: "error",
          showClose: true,
          message: "请输入搜索内容！",
        });
        return;
      }
      this.loading = Loading.service({
        fullscreen: true,
        text: "数据加载中...",
      });
      let data = new FormData();
      data.append("value", this.value);
      axios
        .post("http://222.211.75.228:5000/export.php?act=query", data, {
          timeout: 50000000000,
        })
        .then((res) => {
          console.log("res = ", res);
          this.loading.close();
          if (res.data.data == false) {
            Message({
              type: "warning",
              showClose: true,
              message: "未查询到数据！",
            });
            return;
          }
          res.data.data.forEach((element) => {
            element.ssnr = this.value;
          });
          this.tableData = res.data.data;
          Message({
            type: "success",
            showClose: true,
            message: "查询成功！",
          });
        })
        .catch(() => {
          this.loading.close();
        });
    },
    getQueryVariable(variable,url){
        try {
            var pddurl=url.split("?")[1];
            var query =pddurl.substring(1);
           var vars = query.split("&");
           for (var i=0;i<vars.length;i++) {
                   var pair = vars[i].split("=");
                   if(pair[0] == variable){return pair[1];}
           }
           return(false);
       } catch (e) {
           return(false);
       }
    },
    getPddUrl(){
        var pddurl=window.location.hash.replace('#/','');
        var oldpddurl=''
        if(pddurl){
            pddurl=pddurl.replace("?pddurl=","")
            oldpddurl=pddurl
        }
                if(pddurl.indexOf('invite_code')>-1){
                    pddurl=this.getQueryVariable('invite_code',pddurl);
                    if(!pddurl){
                    pddurl=oldpddurl
                }
                }
        if(pddurl){
            return decodeURIComponent(pddurl)
        }else{
            return false
        }
    }
  },

  destroyed() {},
};
</script>

<style scoped>
@import url("./index.css");
</style>